<script>
export default {
  data() {
    return {
      viewData: {},
      viewGarage: [],
    }
  },
  mounted() {
    this.mountedData()
  },
  methods: {
    async mountedData() {
      await this.$http.get(`/user/getUserId/${this.$route.query.userId}`)
        .then(res => {
          this.viewData = res.data
        }).catch(err => {
          console.log(err)
          // eslint-disable-next-line no-alert
          alert('ERROR! PLEASE CONTACT TO DEVELOPER')
        })
      await this.$http.get(`/car/garage/${this.$route.query.userId}`)
        .then(res => {
          this.viewGarage = res.data
        }).catch(err => {
          console.log(err)
          // eslint-disable-next-line no-alert
          alert('ERROR! PLEASE CONTACT TO DEVELOPER')
        })
    },
  },
}
</script>

<template>
  <section>
    <div
      class="row col-xl-8 col-12 align-items-start justify-content-center tb-border-color-light tb-border-radius-0-5-rem mx-auto p-2 bg-white">
      <div class="col-12 col-md-6 mt-2">
        <div class="col-12 text-center">
          <a class="tb-sub-title">User Details</a>
          <hr>
        </div>
        <table style="width:100%">
          <tr>
            <th>User Full Name:</th>
            <td>{{ viewData.userFirstname }} {{ viewData.userLastname }}
            </td>
          </tr>
          <tr>
            <th>User Name:</th>
            <td>{{ viewData.userUsername }}
            </td>
          </tr>
          <tr>
            <th>User Language:</th>
            <td>{{ viewData.userLanguage ? viewData.userLanguage.toUpperCase() : '' }}</td>
          </tr>
          <tr>
            <th>User Birthday:</th>
            <td>{{ viewData.userBirthDate }}</td>
          </tr>
          <tr>
            <th>User Wallet ID:</th>
            <td>{{ viewData.userWalletId }}</td>
          </tr>
          <tr>
            <th>User Email:</th>
            <td>{{ viewData.userEmail }}</td>
          </tr>
        </table>
      </div>
      <div class="col-12 col-md-6 mt-2">
        <div class="col-12 text-center">
          <a class="tb-sub-title">Race Details</a>
          <hr>
        </div>
        <table style="width:100%">
          <tr>
            <th>User Race Lose:</th>
            <td>{{ viewData.userRaceLose }}</td>
          </tr>
          <tr>
            <th>User Race Win:</th>
            <td>{{ viewData.userRaceWin }}</td>
          </tr>
          <tr>
            <th>User Rank:</th>
            <td>{{ viewData.userRank }}</td>
          </tr>
          <tr>
            <th>User Rank Diff:</th>
            <td>{{ viewData.userRankDiff }}</td>
          </tr>
          <tr>
            <th>User Rank Level:</th>
            <td>{{ viewData.userRankLevel }}</td>
          </tr>
          <tr>
            <th>User Tourment Win:</th>
            <td>{{ viewData.userTournamentWin }}</td>
          </tr>
          
        </table>
      </div>
    </div>
    <div
      class="row col-xl-8 col-12 align-items-start justify-content-center tb-border-color-light tb-border-radius-0-5-rem mx-auto p-2 bg-white mt-2">
      <div class="col-12">
        <div class="col-12 text-center">
          <a class="tb-sub-title">User Garage</a>
          <hr>
        </div>
        <div class="row">
          <div v-for="(item, index) in viewGarage" :key="index" class="col-12 col-md-6 mt-2">
            <div class="col-12 text-center">
              <a class="tb-sub-title">Car {{index+1}}</a>
              <hr>
            </div>
            <table style="width:100%">
              <tr>
                <th>Brand Name:</th>
                <td>{{ item.brand.brandName }}</td>
              </tr>
              <tr>
                <th>Car Fuel:</th>
                <td>{{ item.carFuel }}</td>
              </tr>
              <tr>
                <th>Car Health:</th>
                <td>{{ item.carHealth }}
                </td>
              </tr>
              <tr>
                <th>Model Name:</th>
                <td>{{ item.carModel.modelName }}</td>
              </tr>
              <tr>
                <th>Model Acceleration:</th>
                <td>{{ item.carModel.modelAcceleration }}</td>
              </tr>
              <tr>
                <th>Model Brake:</th>
                <td>{{ item.carModel.modelBrake }}</td>
              </tr>
              <tr>
                <th>Model Grip:</th>
                <td>{{ item.carModel.modelGrip }}</td>
              </tr>
              <tr>
                <th>Model Stock Price:</th>
                <td>{{ item.carModel.modelStockPrice }}</td>
              </tr>
              <tr>
                <th>Model Top Speed:</th>
                <td>{{ item.carModel.modelTopSpeed }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
th,
td {
  padding: 10px;
}

tr:nth-child(odd) {
  background-color: rgb(243, 243, 243);
}

tr:nth-child(even) {
  background-color: rgb(228, 228, 228);
}
</style>
